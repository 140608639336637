<template>
  <div id="ContractorRegistrationScreen">
    <v-container>
      <v-card>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Daftar Pengguna</h5>
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pa-0">
          <template>
            <v-data-table
              :headers="headers"
              :items="contractorList"
              class="elevation-0"
              mobile-breakpoint="0"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td
                    class="detailLink"
                    @click="getDetail(props.item.pending_task_id)"
                  >
                    {{ props.item.company_name }}
                  </td>
                  <td>{{ props.item.company_address }}</td>
                  <td>{{ props.item.responsible_name }}</td>
                  <td>{{ props.item.phone_number }}</td>
                  <td>{{ props.item.email }}</td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>

      <v-dialog v-model="dialog" max-width="1000">
        <v-card class="mx-auto" outlined>
          <v-app-bar dense color="transparent">
            <v-toolbar-title>
              <h5>Detail Pengguna</h5>
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0">
            <div class="pa-5">
              <v-row>
                <v-col class="col-3">
                  Nama Usaha/ Perusahaan
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.company_name }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Alamat
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.company_address }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Kode Pos
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.postal_code }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Cakupan Area Wilayah Kerja
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.working_area }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Status Perusahaan
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.company_status }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Nama Penanggung Jawab
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.responsible_name }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Nomor Handphone Penanggung Jawab
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.phone_number }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Email Penanggung Jawab
                </v-col>
                <v-col class="col-9"> :&ensp; {{ dataDetail.email }} </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Nomor KTP
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.id_card_number }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Nomor NPWP Penanggung jawab
                </v-col>
                <v-col class="col-9"> :&ensp; {{ dataDetail.npwp }} </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Tanggal Daftar
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.requested_date }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-6">
                  <div>Foto KTP</div>
                  <div class="mt-5">
                    <expandable-image
                      :src="dataDetail.url_attachment_ktp"
                      width="500px"
                      close-on-background-click
                    />
                  </div>
                </v-col>
                <v-col class="col-6">
                  <div>Foto NPWP</div>
                  <div class="mt-5">
                    <expandable-image
                      :src="dataDetail.url_attachment_npwp"
                      width="500px"
                      close-on-background-click
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import RegistrationService from "@/services/RegistrationService";

export default {
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      dataDetail: {},
      contractorList: [],
      headers: [
        { text: "Nama Usaha", sortable: false },
        { text: "Alamat", sortable: false },
        { text: "Nama Penanggung Jawab", sortable: false },
        { text: "Nomor Telepon Penanggung Jawab", sortable: false },
        { text: "Email Penanggung Jawab", sortable: false }
      ]
    };
  },

  mounted() {
    this.getUser();
  },

  methods: {
    getUser() {
      RegistrationService.getRegistrationContractor({
        menu_name: "Request Register Contractor"
      })
        .then(response => {
          this.contractorList = response.data.data;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getDetail(id) {
      this.$root.$loaderModal.start("Loading...");

      const payload = {
        menu_name: "Request Register Contractor",
        id: id
      };

      RegistrationService.getRegistrationContractorDetail(payload)
        .then(response => {
          if (response) {
            this.dataDetail = response.data.data;
            this.dialog = true;
          }
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    }
  }
};
</script>
